import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { ConfigContext } from "../../App";
import { CLOUDIDS, EVENTTYPES, EVENT_SOURCE, IMS_EVENT, MARKETO_ID, OMEGA_ENV, REGIONS, URLS } from '../../constants';
import { ServiceRegistry } from '../../models/service-registry';
import { SubscriptionService } from '../../models/subscription-service';
import { BsoMapping } from '../../models/mapping-service';
import { PNSWIZARD, RegistryCloud, RegistryProduct, RegistryService } from '../../service-registry.d';
import Spinner from '../spinner/spinner';
import moment from 'moment-timezone';
import { AxiosError, AxiosResponse } from 'axios';
import parse from 'html-react-parser';
import { cloneArrayOfObject, HideFooter, isBizible, removeDuplicate } from '../../utils';
import { CreateSubscriptionErrorType, ServiceRegions, SlackStatus, SubscriptionType } from '../../proptype';
import ReactTooltip from 'react-tooltip';
import OMEGA from '../../omega';
import GenericError from '../genericerror/genericerror';
declare global {
    interface Window {
        slackWindow: any,
        removeSlackWindow: any
    }
}
export default function ProactiveNotification() {
    const eventDefaultValue = {
        element: "Completed subscription",
        type: "Button",
        action: "click",
        widget: {
            name: "PNS",
            type: "panel"
        },
        feature: "proactive notification"
    }
    const locales = useContext(ConfigContext).appConfig.languageData;
    const [userLanguage, setUserLanguage] = useState("English (United States)");
    const [email, setEmail] = useState("");
    const [cloudList, setCloudList] = useState<any[]>([]);
    const [entitlements, setEntitlements] = useState<string[]>([]);
    const [slackRemoveURL, setSlackRemoveURL] = useState<string>(URLS.REVOKE_SLACK);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [error, setError] = useState(false);
    const [apiError, setAPIError] = useState(false);
    const [apiErrorType, setAPIErrorType] = useState<CreateSubscriptionErrorType>(CreateSubscriptionErrorType.ClientError);
    const [activeWizard, setActiveWizard] = useState('product');
    const [showCustomRegions, setShowCustomRegions] = useState(false);
    const [showInfo, setShowInfo] = useState({
        slack: false,
        language: false,
        timeZome: false
    });
    const [bsomapping, setBsoMapping] = useState<any>({});
    const [globalEvent, setGlobalEvent] = useState(true);
    const [globalEventTypes, setGlobalEventTypes] = useState<any[]>([]);
    const [globalRegions, setGlobalRegions] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [unsubscribeAll, setUnsubscribeAll] = useState(false);
    const [slackStatus, setSlackStatus] = useState(-1);
    const [slackNotificationEnabled, setSlackNotificationEnabled] = useState(false);
    const [slackSubscriptions, setSlackSubscriptions] = useState<any>({});
    const [bizibleEnvironmentChecked, setBizibleEnvironmentChecked] = useState(false);
    const [showSlackConfirm, setShowSlackConfirm] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [userTimezone, setUserTimezone] = useState('UTC');
    const [userTimezoneWithOffset, setUserTimezoneWithOffset] = useState('UTC');
    const { type }: any = useParams();
    const histroyAPI = useHistory();

    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const bsoMapping: BsoMapping = BsoMapping.init();
    const subscriptionService: SubscriptionService = SubscriptionService.init();
    subscriptionService.setLocales(locales);

    useEffect(() => {
        setGlobalEventTypes(cloneArrayOfObject(EVENTTYPES));
        setGlobalRegions(cloneArrayOfObject(REGIONS));
        if (window.adobeIMS) {
            getAllData();
        } else {
            document.addEventListener(IMS_EVENT, getAllData);
        }

        return () => {
            setMounted(false);
            document.removeEventListener(IMS_EVENT, getAllData);
        }
    }, []);
    function getAllData() {
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }

        window.scrollTo(0, 0);

        setUserLanguage(subscriptionService.getUserDetail().userLanguage);
        setEmail(subscriptionService.getUserDetail().email);

        let pageType = isEdit() ? "edit" : "create";
        OMEGA.init().updateAndTrack('page', {
            hierarchy: `home:manage-subscription:${pageType}`,
            name: `Manage Subscription ${pageType} - Adobe status`,
            url: encodeURIComponent(window.location.href),
            type: "pns",
            env: OMEGA_ENV
        });

        subscriptionService.getSlackSubscription().then((result: any) => {
            let slackSubscriptionData = result.data;
            if (slackSubscriptionData?.channel && slackSubscriptionData?.active) {
                setSlackSubscriptions(slackSubscriptionData);
                setSlackRemoveURL(slackSubscriptionData.workSpaceUrl);
            }
        });

        Promise.all([subscriptionService.getSubscriptions(), serviceRegistry.fetch(true), bsoMapping.fetch(true)])
            .then((result: any) => [result[0].data, result[1].data, result[2].data])    
            .then((result: any) => {
                if (mounted) {
                    setBsoMapping(result[2]);
                    let [subscriptionResult]: any = result;
                    setSlackStatus(subscriptionResult.slackStatus);
                    setSlackNotificationEnabled(subscriptionResult.slackNotificationEnabled);

                    if (isEdit()) {
                        setCloudList(serviceRegistry.getUpdatedCloudList(serviceRegistry.mergeOfferingsIntoProduct(subscriptionResult), (_isBizibleEvironmentSelected: boolean) => {
                            setBizibleEnvironmentChecked(_isBizibleEvironmentSelected);
                        },result[2]));
                    }
                    setPageLoading(false);
                }
            })
            .catch(() => {
                setPageLoading(false);
                setError(true);
            });

        if (isEdit()) {
            setGlobalEvent(false);
        } else {
            Promise.all([subscriptionService.getEntitlementCodeFromAPS(), serviceRegistry.fetch(true)])
                .then((result) => {
                    if (mounted) {
                        let _entitlements: string[] = result[0],
                            _allServiceCodes = serviceRegistry.getServiceCodeMap();

                        _entitlements = _entitlements.filter((code: string) => {
                            return _allServiceCodes.has(code);
                        });

                        if (_entitlements.length) {
                            setEntitlements(_entitlements);
                            setCloudList(serviceRegistry.getProductsByCloud(_entitlements));
                        } else {
                            setCloudList(serviceRegistry.getProductsByCloud());
                        }
                        setPageLoading(false);
                    }
                })
                .catch(() => {
                    setPageLoading(false);
                    setError(true);
                });
        }

    }
    function isEdit() {
        return type === 'edit';
    }

    function isCloudOpened() {
        return cloudList.some((cloud: RegistryCloud) => cloud.isOpen);
    }

    function isNoProductSelected() {
        return !cloudList.filter((cloud: RegistryCloud) => {
            return cloud.cloudProducts.some((product: RegistryProduct) => product.active)
        }).length;
    }

    function disableButton() {
        return activeWizard === PNSWIZARD.Product && !isEdit() && isNoProductSelected()
    }

    function getSelectedProducts() {

        return cloudList.reduce((products: RegistryProduct[], cloud: RegistryCloud) => {
            Array.prototype.push.apply(products, cloud.cloudProducts.filter((product: RegistryProduct) => product.active));
            return products;
        }, []);
    }

    function hasEnvironments() {
        return cloudList.some((cloud: RegistryCloud) => cloud.cloudProducts.some((product: RegistryProduct) => product.active && product.showEnvironment))
    }

    function groupByDataCenter(product: RegistryProduct) {
       
        let result: any = [];
        if (product.productOfferings?.length) {
            result = product.productOfferings
                .filter((offering: RegistryService) => offering.active)
                .reduce((dataCenters: any[], offering: RegistryService) => {
                    Array.prototype.push.apply(dataCenters, offering.serviceDataCenters);
                    return dataCenters;
                }, []);
        }

        return removeDuplicate(result, 'name')
            .sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    function validateTimeZone(callback: Function) {
        setAPIError(false);
        setLoading(true);
        subscriptionService.validateTimeZone(moment.tz.guess())
            .then((response: AxiosResponse) => response.data)
            .then((response: any) => {
                if (response.timeZone === "UTC") {
                    setUserTimezoneWithOffset(response.timeZone);
                } else {
                    setUserTimezoneWithOffset(
                        response.timeZone +
                        " (" +
                        response.timezoneOffset +
                        ")"
                    );
                }
                setUserTimezone(response.timeZone);
                window.scrollTo(0, 0);
                setLoading(false);
                callback();
            })
            .catch((error: any) => {
                let offset = new Date().getTimezoneOffset(),
                    absOffset = Math.abs(offset);

                let currentTimezone = moment.tz.guess();
                setUserTimezone(currentTimezone);
                setUserTimezoneWithOffset(
                    currentTimezone +
                    " (" +
                    "UTC " +
                    (offset > 0 ? "-" : "+") +
                    Math.floor(absOffset / 60) +
                    ":" +
                    (absOffset % 60) +
                    ")"
                );
                window.scrollTo(0, 0);
                setLoading(false);
                callback();
            });
    }

    function gotoConfrim() {
        if (globalEvent) {
            updatedProducts();
        }
        setActiveWizard(PNSWIZARD.Confirm);
    }

    function updatedProducts() {

        selectedProducts.forEach((product: RegistryProduct) => {
            if (product.productOfferings?.length) {
                product.productOfferings = product.productOfferings.map((offering: RegistryService) => {
                    if (offering.active) {
                        offering.regions = cloneArrayOfObject(globalRegions);
                    }
                    return offering;
                });
            }

            product.regions = cloneArrayOfObject(globalRegions);
            product.eventTypes = cloneArrayOfObject(globalEventTypes);
            return product;
        });
        setSelectedProducts([...selectedProducts]);
    }

    function hideAllDataCenters(dataCenters: any[]) {
        return dataCenters.map((dataCenter: any) => {
            dataCenter.isOpen = false
            return dataCenter;
        });
    }

    function parseEnvironmentName(name: string) {
        let split = name.split(/-(.+)/);
        if (split.length > 1) return split[1];

        return name;
    }

    function getCloudName(productId: string) {
        return serviceRegistry.getCloudName(productId);
    }

    function stringifyOffer(offerings?: RegistryService[]) {
        if (offerings?.length) {
            return offerings?.filter((offering: RegistryService) => offering.active)
                .map((offering: RegistryService) => offering.name)
                .join(', ');
        }
        return ''
    }

    function joinEvents(eventTypes?: any[]) {
        if (eventTypes?.length) {
            return eventTypes.filter((eventType: any) => eventType.active)
                .map((eventType: any) => locales[eventType.name as keyof typeof locales])
                .join(', ');
        }
        return '';
    }

    function stringifyRegion(regions?: any[]) {
        if (regions?.length) {
            return regions.filter((region: any) => region.active)
                .map((region: any) => region.name)
                .join(', ');
        }
        return '';
    }

    function stringifyEnvironment(product: RegistryProduct) {

        let selectedBizibleEnvironments: string[] = [], enviroments: string[] = [];
        if (product.productOfferings?.length) {
            let selectedOfferings: RegistryService[] = [];
            product.productOfferings.forEach((offering: RegistryService) => {
                if (offering.active) {
                    selectedOfferings.push(offering);

                    if (isBizible(offering)) {
                        if (offering?.serviceEnvironments?.length) {
                            Array.prototype.push.apply(selectedBizibleEnvironments, offering.serviceEnvironments.filter((environment: RegistryService) => environment.active)
                                .map((environment: RegistryService) => environment.id));
                        }
                    }
                }


            });

            enviroments = selectedOfferings.reduce((enviroments: string[], offering: RegistryService) => {
                if (offering?.serviceEnvironments?.length) {
                    Array.prototype.push.apply(enviroments, offering.serviceEnvironments.filter((environment: RegistryService) => environment.active && !selectedBizibleEnvironments.includes(environment.id))
                        .map((environment: RegistryService) => environment.name));
                }
                return enviroments;
            }, []);

            enviroments = enviroments.filter((enviroment: string, position: number) => enviroments.indexOf(enviroment) === position);

            if (selectedBizibleEnvironments.length) {
                enviroments.push(ServiceRegions.Americas);
            }

        } else {
            if (product?.serviceEnvironments?.length) {
                enviroments = product.serviceEnvironments?.filter((environment: RegistryService) => environment.active && !selectedBizibleEnvironments.includes(environment.id))
                    .map((environment: RegistryService) => environment.name);
            }
        }
        enviroments = enviroments.filter(function(item:any, pos:any) {
            return enviroments.indexOf(item) === pos;
        })
        return enviroments.sort((a: string, b: string) => a.localeCompare(b))
            .join(', ');
    }

    function redirect() {
        histroyAPI.push({
            pathname: "/proactive-notifications/manage",
            state: {
                page: 'pns'
            }
        });
    }

    function subscribe() {
        let type = isEdit() ? "edit subscription" : "create subscription";
        setLoading(true);
        if (unsubscribeAll) {
            subscriptionService.deleteSelfSubscription()
                .then(() => {
                    setLoading(false);
                    histroyAPI.push({
                        pathname: "/proactive-notifications/manage",
                        state: {
                            page: 'pns'
                        }
                    });
                })
                .catch(() => {
                    setLoading(false);
                    setAPIError(true);
                })
        } else {
            let payload = {
                timeZone: userTimezone,
                language: userLanguage || "English (United States)",
                source: EVENT_SOURCE,
                subscriptions: generatePayloadData(),
                slackNotificationEnabled: slackNotificationEnabled
            }

            subscriptionService.subscibeProducts(payload)
                .then(() => {
                    OMEGA.init().updateAndTrack("event", [{
                        ...eventDefaultValue,
                        element: "Completed subscription",
                        attributes: {
                            completedType: type
                        }
                    }]);
                    setLoading(false);




                    if (slackStatus !== SlackStatus.Installed && slackNotificationEnabled) {
                        installSlack();
                    }
                    else if ((slackStatus === SlackStatus.Installed || slackStatus === SlackStatus.RevokePending) && !slackNotificationEnabled) {
                        window.removeSlackWindow = window.open(slackRemoveURL, "_blank");
                        redirect();
                        OMEGA.init().updateAndTrack("event", [{
                            ...eventDefaultValue,
                            element: "Remove slack",
                            widget: {
                                name: "Slack",
                                type: "panel"
                            },
                            attributes: {
                                completedType: type
                            }
                        }]);
                    } else {
                        redirect();
                        if (slackNotificationEnabled && slackSubscriptions.channel) return;
                    }
                })
                .catch((error: AxiosError) => {
                    OMEGA.init().updateAndTrack("event", [{
                        ...eventDefaultValue,
                        element: "Failed subscription",
                        attributes: {
                            completedType: type
                        }
                    }]);
                    setLoading(false);
                    if (error.response?.status && error.response?.status >= 500) {
                        setAPIErrorType(CreateSubscriptionErrorType.ServerError);
                    } else {
                        setAPIErrorType(CreateSubscriptionErrorType.ClientError);
                    }
                    setAPIError(true);

                })
        }

    }

    function installSlack() {
        if (!window.adobeIMS?.isSignedInUser()) {
            window.adobeid.redirect_uri = window.location.href;
            window.adobeIMS.signIn();
            return;
        }
        let URL = URLS.ADD_TO_SLACK_URL + "&code=" + window.adobeIMS?.getAccessToken().token;
        window.slackWindow = window.open(URL, "_blank");
        redirect();
        OMEGA.init().updateAndTrack("event", [{
            ...eventDefaultValue,
            element: "Add slack",
            widget: {
                name: "Slack",
                type: "panel"
            },
            attributes: {
                completedType: type
            }
        }]);
    }

    function showAPIError() {

        if (apiError && apiErrorType === CreateSubscriptionErrorType.ServerError) {
            return <div className="error-msg show">{parse(locales.subscription_progress_error)}</div>
        } else if (apiError && apiErrorType === CreateSubscriptionErrorType.ClientError) {
            return <div className="error-msg show">{parse(locales.subscription_error_info)}</div>
        } else {
            return <></>
        }

    }

    function isAllOfferSelected(product: RegistryProduct) {
        if (product.productOfferings?.length) {
            return product.productOfferings?.length === product.productOfferings?.filter((offering: RegistryService) => offering.active).length
        }

        return false;
    }

    function isMarketo(product: RegistryProduct) {
        return (product.id === MARKETO_ID || product.name.toLowerCase().indexOf('marketo')) !== -1;
    }

    function generatePayloadData() {
        return selectedProducts.map((product: RegistryProduct) => {
            let regions: any = [],
                result: any = {
                    product: {
                        id: product.id,
                        name: product.name,
                        type: product.productOfferings?.length && !isAllOfferSelected(product) ? SubscriptionType.Offering : SubscriptionType.Product
                    },
                    cloudName: getCloudName(product.id),
                    eventType: product.eventTypes?.filter((eventType: any) => eventType.active).map((eventType: any) => eventType.id),
                    regions: [] // will be updated if no offering
                };

            if (isMarketo(product)) { // special case, for marketo always type should be 2
                result.product.type = SubscriptionType.Offering;
            }

            if (!product.showEnvironment) {
                regions = product.regions?.filter((region: any) => region.active)
                    .map((region: any) => { return { id: region.id, name: region.name } })
            }
            if (product.productOfferings?.length) {
                result.productOfferings = product.productOfferings
                    .filter((offering: RegistryService) => offering.active)
                    .reduce((productOfferings: any[], offering: RegistryService) => {
                        let outOffering: any = {
                            id: offering.id,
                            name: offering.name,
                        }
                        if (!product.showEnvironment) {
                            outOffering.regions = regions;
                        } else {
                            outOffering.environments = [];
                            if (offering.serviceEnvironments?.length) {
                                outOffering.environments = offering.serviceEnvironments
                                    .filter((environment: RegistryService) => environment.active)
                                    .map((environment: any) => { return { id: environment.grpId, name: environment.name } })
                            }

                        }
                        productOfferings.push(outOffering);
                        return productOfferings;
                    }, [])

            } else {
                if (product.showEnvironment) {
                    if (product.serviceEnvironments?.length) {
                        result.environments = product.serviceEnvironments
                            .filter((environment: RegistryService) => environment.active)
                            .map((environment: any) => { return { id: environment.grpId, name: environment.name } })
                    }
                } else {
                    result.regions = regions;
                }
            }

            return result;
        });
    }

    function allProductsRegionAndEventSelected() {
        let eventTypes: any, regions: any, environments: any;
        if (globalEvent) {
            eventTypes = globalEventTypes.filter((eventType: any) => eventType.active);

            if (!eventTypes?.length) return false;

            regions = globalRegions.filter((region: any) => region.active);

            if (!regions?.length) return false;
        } else {
            for (const key in selectedProducts) {
                if (Object.prototype.hasOwnProperty.call(selectedProducts, key)) {
                    const product: RegistryProduct = selectedProducts[key];
                    eventTypes = product.eventTypes?.filter((eventType: any) => eventType.active);

                    if (!eventTypes?.length) return false;

                    if (product.showEnvironment) {
                        if (product.productOfferings?.length) {
                            let state = product.productOfferings.filter((offering: RegistryService) => offering.active)
                                .some((offering: RegistryService) => {
                                    return offering.serviceEnvironments?.some((environment: RegistryService) => environment.active)
                                });
                            if (!state) return false;
                        } else { // without offering
                            environments = product.serviceEnvironments?.filter((environment: RegistryService) => environment.active)
                            if (!environments?.length) return false;
                        }

                    } else {
                        regions = product.regions?.filter((region: any) => region.active);
                        if (!regions?.length) return false;
                    }
                }
            }
        }

        return true;

    }

    function gotoNextWizard() {
        if (activeWizard === PNSWIZARD.Product) {
            let _unsubscribeAll = isNoProductSelected() && isEdit();

            setUnsubscribeAll(_unsubscribeAll);
            if (_unsubscribeAll) {
                validateTimeZone(() => setActiveWizard(PNSWIZARD.Confirm));
            } else {
                setActiveWizard(PNSWIZARD.Region);
                let _hasEnvironments = hasEnvironments(),
                    _selectedProducts = getSelectedProducts();
                if (!isEdit()) {
                    setGlobalEvent(!_hasEnvironments);
                }
                setShowCustomRegions(_hasEnvironments);
                setSelectedProducts(_selectedProducts);
                window.scrollTo(0, 0);
            }
        }
        else {
            validateTimeZone(() => setActiveWizard(PNSWIZARD.Notification));
            window.scrollTo(0, 0);
        }

    }

    function isWizardCompleted(wizard: PNSWIZARD) {

        return (PNSWIZARD.Region === activeWizard && wizard === PNSWIZARD.Product) ||
            (PNSWIZARD.Notification === activeWizard && [PNSWIZARD.Product, PNSWIZARD.Region].includes(wizard)) ||
            (PNSWIZARD.Confirm === activeWizard && [PNSWIZARD.Product, PNSWIZARD.Region, PNSWIZARD.Notification].includes(wizard))

    }

    function isBizibleDataCenter(dataCenter: any): boolean {
        return (dataCenter.name.toLowerCase() === 'bizible')
    }

    function slackPreference() {
        let slackText = '', slackInfo = '';
        if (slackStatus !== SlackStatus.Installed && slackNotificationEnabled) {
            slackText = locales.slack_setup_pending;
            slackInfo = locales.slack_setup_pending_info;
        }

        if ((slackStatus === SlackStatus.Installed || slackStatus === SlackStatus.RevokePending) && !slackNotificationEnabled) {
            slackText = locales.slack_removal;
            slackInfo = locales.slack_removal_info;
        }
        if (slackNotificationEnabled && slackSubscriptions.channel) {
            slackText = slackSubscriptions.channel;
            slackInfo = locales.manage_subscription_confirm_preferances_page_slack_channel_icon_info;
        }

        if (slackText && !slackInfo) {
            return (
                <>
                    <div>{locales.slack}: <b>{slackText}</b></div>
                </>
            );
        } else if (slackText && slackInfo && slackNotificationEnabled && slackSubscriptions.channel) {
            var workspaceUrl = new URL(slackSubscriptions.workSpaceUrl)
            return (
                <>
                    <div>{locales.slack}: <a href={workspaceUrl.origin + '/channels/' + (slackText.startsWith('#') ? slackText.slice(1) : slackText)} rel="noreferrer" target="_blank" className="slack-channel-link"><b>{slackText.startsWith('#') ? slackText : `#${slackText}`}</b></a>                        <i onClick={() => {
                        setShowInfo({
                            ...showInfo,
                            slack: !showInfo.slack
                        })
                    }} className="info-icon"></i>
                    </div>
                    <div className={`slack-info ${showInfo.slack ? 'active' : ''}`}>{parse(slackInfo)}</div>
                </>
            )
        } else if (slackText && slackInfo) {
            return (
                <>
                    <div>{locales.slack}:<b>{slackText}</b>
                        <i onClick={() => {
                            setShowInfo({
                                ...showInfo,
                                slack: !showInfo.slack
                            })
                        }} className="info-icon"></i>
                    </div>
                    <div className={`slack-info ${showInfo.slack ? 'active' : ''}`}>{parse(slackInfo)}</div>
                </>
            )
        } else {
            return <></>;
        }

    }

    function renderSlackConfirm() {
        return (
            <>
                <div className="backdrop"></div>
                <div className="slack-confirm">
                    <div className="slack-title">{locales.slack_confirm_title}</div>
                    <div className="content">
                        <ul>
                            <li>{locales.slack_confirm_select_channel}</li>
                            <li>{locales.slack_confirm_support}</li>
                            <li>{locales.slack_confirm_proceed}</li>
                        </ul>
                    </div>
                    <div className="buttons">
                        <button className='btn primary' onClick={() => {
                            setShowSlackConfirm(false);
                        }}>{locales.ok_txt}</button>

                    </div>
                </div>
            </>
        )
    }

    function renderPerferences() {
        return (
            <div className="preference">
                {(activeWizard === PNSWIZARD.Confirm) ?
                    <><div>{locales.sb_email}: <b>{email}</b></div>{slackPreference()}</> : ''}

                <div>{locales.sb_lang}: <b>{userLanguage}</b>
                    <i onClick={() => {
                        setShowInfo({
                            ...showInfo,
                            language: !showInfo.language
                        })
                    }} className="info-icon"></i>
                </div>
                <div className={`lang-info ${showInfo.language ? 'active' : ''}`}>{parse(locales.lang_info)}</div>
                <div>{locales.timezone}: <b>{userTimezoneWithOffset}</b>
                    <i onClick={() => {
                        setShowInfo({
                            ...showInfo,
                            timeZome: !showInfo.timeZome
                        })
                    }} className="info-icon"></i></div>
                <div className={`timezone-info ${showInfo.timeZome ? 'active' : ''}`} >{parse(locales.timezone_info)}</div>
            </div>
        );
    }

    function renderConfirmTiles() {
        return (
            <div className="selected-products">
                {
                    selectedProducts.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((product: RegistryProduct) => {
                        return (
                            <div key={`selected-${product.id}`} className="row">
                                <div className="col-md-4">
                                    <div className="product-name">{product.name}</div>
                                    <span className="cloud-name">{getCloudName(product.id)}</span>
                                </div>
                                <div className="col-md-8">
                                    {
                                        product.productOfferings?.length ?
                                            <div className="product-offerings">
                                                <b>{locales.product_offering}: </b>
                                                {stringifyOffer(product.productOfferings)}
                                            </div> : ''
                                    }
                                    <div className="rigion-selected">
                                        <b>{locales.region_locations}: </b>
                                        {
                                            product.showEnvironment ?
                                                stringifyEnvironment(product) : stringifyRegion(product.regions)
                                        }
                                    </div>

                                    <div className="selected-events">
                                        <b>{locales.event_types}: </b>{joinEvents(product.eventTypes)}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }

            </div>
        );
    }


    function envToBsoMapping(productoroffering: any ) {

        let mappedEnv:any = [];
            productoroffering.serviceEnvironments?.forEach((environment: any) => {
                environment.grpId = bsomapping.find((map:any) => map.bsoId === environment.id)?.groupId;
               
                mappedEnv.push(environment);
                
            })
        return mappedEnv.filter((map:any, index:any, self:any) =>
                index === self.findIndex((mapindex:any) => mapindex.grpId === map.grpId)
            );
    }
    function filterAndRenderOfferingsEnvironment(product: RegistryProduct) {
        let enviromentMap: any = {};
        if (product.productOfferings?.length) {
            return (
                <ul className={`region checkbox-group ${window.locale}`}>
                    {
                        product.productOfferings
                            .filter((productOffering: any) => productOffering.active)
                            .map((productOffering: any) => {
                                let filteredEnv = envToBsoMapping(productOffering);
                               
                                return filteredEnv?.map((environment: any) => {
                                   
                                    if (!enviromentMap[environment.grpId]) {
                                        enviromentMap[environment.grpId] = environment.grpId;
                                        return (
                                            <li key={`env-${environment.grpId}`} >
                                                <label className="checkbox-container">{environment.name}
                                                    <input type="checkbox"
                                                        onChange={() => {
                                                            environment.active = !environment.active;
                                                            setSelectedProducts([...selectedProducts]);
                                                        }}
                                                        name={`environment${environment.grpId}[]`}
                                                        value={environment.grpId}
                                                        checked={!!environment.active}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </li>
                                        )

                                    }
                                });
                            })
                    }
                </ul>
            );

        } else {
            let filteredEnv = envToBsoMapping(product);
            return (
                <ul className={`region checkbox-group ${window.locale}`}>
                    {
                        
                        
                        filteredEnv?.map((environment: any) => {
                          
                            return (
                                <li key={`env-${environment.grpId}`} >
                                    <label className="checkbox-container">{environment.name}
                                        <input type="checkbox"
                                            onChange={() => {
                                                environment.active = !environment.active;
                                                setSelectedProducts([...selectedProducts]);
                                            }}
                                            name={`environment${environment.grpId}[]`}
                                            value={environment.grpId}
                                            checked={!!environment.active}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    function renderEnvironment(product: RegistryProduct) {
        if (isMarketo(product) && product.productOfferings?.length) {
            let dataCenters: any = groupByDataCenter(product);
            return (
                <div className="environment-group environment">
                    <div className="region-info">
                        {locales.capabilities_info}
                    </div>
                    {
                        dataCenters.map((dataCenter: any, dindex: number) => {
                            return (
                                <div key={`datacenter-g-${dindex}`} className="groups">
                                    <span className={`environment-group-link ${dataCenter.isOpen ? 'active' : ''}`} onClick={() => {
                                        let newState = !dataCenter.isOpen
                                        dataCenters = hideAllDataCenters(dataCenters);
                                        dataCenter.isOpen = newState;
                                        setSelectedProducts([...selectedProducts]);
                                    }} >{dataCenter.name}</span>
                                </div>
                            )
                        })
                    }
                    <div className="enviroment-regions-container">
                        {
                            dataCenters.map((dataCenter: any, index: number) => {
                                if (isBizibleDataCenter(dataCenter)) {
                                    return (
                                        <div key={`datacenter-v-${index}`} className={`environment-region-list ${dataCenter.isOpen ? 'active' : ''}`}>
                                            <div className="environment-group-title">
                                                {dataCenter.name}
                                            </div>
                                            <ul className="environment-region">
                                                <li >
                                                    <label className="checkbox-container">{ServiceRegions.Americas}
                                                        <input type="checkbox"
                                                            onChange={() => {
                                                                let newState = !bizibleEnvironmentChecked;
                                                                setBizibleEnvironmentChecked(newState);
                                                                dataCenter.serviceEnvironments.forEach((environment: RegistryService) => {
                                                                    environment.active = newState;
                                                                });
                                                                setSelectedProducts([...selectedProducts]);
                                                            }}
                                                            name={`environment-bizible[]`}
                                                            value={1}
                                                            checked={bizibleEnvironmentChecked}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    );
                                } else {
                                    let filteredEnv = envToBsoMapping(dataCenter);
                                    return (
                                        <div key={`datacenter-v-${index}`} className={`environment-region-list ${dataCenter.isOpen ? 'active' : ''}`}>
                                            <div className="environment-group-title">
                                                {dataCenter.name}
                                            </div>
                                            <ul className="environment-region">
                                                {
                                                   
                                                    filteredEnv
                                                        .map((environment: RegistryService) => {
                                                            environment.parsedName = parseEnvironmentName(environment.name)
                                                            return environment;
                                                        })
                                                        .sort((a: RegistryService, b: RegistryService) => a.parsedName?.localeCompare(b.parsedName || ''))
                                                        .map((environment: any) => {
                                                            return (
                                                                <li key={`env-g-${environment.id}`} >
                                                                    <label className="checkbox-container">{environment.parsedName}
                                                                        <input type="checkbox"
                                                                            onChange={() => {
                                                                                environment.active = !environment.active;
                                                                                setSelectedProducts([...selectedProducts]);
                                                                            }}
                                                                            name={`environment${environment.grpId}[]`}
                                                                            value={environment.grpId}
                                                                            checked={!!environment.active}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </li>
                                                            )
                                                        })
                                                }
                                            </ul>
                                        </div>
                                    );
                                }

                            })
                        }
                    </div>
                </div>
            )

        } else {
            return filterAndRenderOfferingsEnvironment(product);
        }

    }

    function renderRegion(product: RegistryProduct) {

        return (
            <ul>
                {
                    product.regions?.map((region: any) => {
                        return (
                            <li key={`region-p-${region.id}`} >
                                <label className="checkbox-container">
                                    {region.name}
                                    <input
                                        type="checkbox"
                                        checked={region.active}
                                        value={region.id}
                                        onChange={() => {

                                            region.active = !region.active;
                                            setSelectedProducts([...selectedProducts]);

                                        }}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        );
                    })
                }
            </ul>
        );
    }

    function renderEventTypes(product: RegistryProduct) {
        return (
            <ul>
                {
                    product.eventTypes?.map((eventType: any) => {
                        return (
                            <li key={`event-p-${eventType.id}`} >
                                <label className="checkbox-container">{locales[eventType.name as keyof typeof locales]}
                                    <input
                                        type="checkbox"
                                        checked={eventType.active}
                                        value={eventType.id}
                                        onChange={() => {
                                            eventType.active = !eventType.active;
                                            setSelectedProducts([...selectedProducts]);
                                        }}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    function renderGlobalSection() {
        return (
            <>
                <label
                    className="global-event"
                    htmlFor="global-event">
                    <input
                        id="global-event"
                        value="globalevent"
                        className="group-selection"
                        name="eventselection"
                        type="radio"
                        onChange={(e) => { setGlobalEvent(e.target.checked) }}
                        checked={globalEvent}
                    />
                    <span>{locales.region_global}</span>
                </label>
                <div className={`region-global-container ${globalEvent ? 'active' : ''}`}>
                    <div className="region-container global">
                        <div className="select-info">{locales.region_info_global}</div>
                        <div className="wrapper">
                            <div className={`region checkbox-group ${window.locale}`}>
                                <div className="title">{locales.region_locations}</div>
                                <ul>
                                    {
                                        globalRegions.map((region: any) => {
                                            return (
                                                <li key={`region-g-${region.id}`}  >
                                                    <label className="checkbox-container">
                                                        {region.name}
                                                        <input
                                                            type="checkbox"
                                                            checked={region.active}
                                                            onChange={() => {
                                                                region.active = !region.active;
                                                                setGlobalRegions([...globalRegions]);
                                                            }}
                                                            value={region.id}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>

                            <div className={`event-type ${window.locale}`}>
                                <div className="title">{locales.event_types}</div>
                                <ul>
                                    {
                                        globalEventTypes.map((eventType: any) => {
                                            return (
                                                <li key={`event-g-${eventType.id}`}>
                                                    <label className="checkbox-container">{locales[eventType.name as keyof typeof locales]}
                                                        <input
                                                            type="checkbox"
                                                            checked={eventType.active}
                                                            onChange={() => {
                                                                eventType.active = !eventType.active;
                                                                setGlobalEventTypes([...globalEventTypes]);
                                                            }}
                                                            value={eventType.id}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function renderNotificationTab() {
        return (
            <div className={`pns-wizard-item ${PNSWIZARD.Notification} animate__animated animate__fadeIn active clearfix`}>
                <p className='communication-pref-title'>{locales.communication_preference}</p>
                <label className="checkbox-container">
                    <span>{locales.sb_email}</span> <span className='mandatory_field'>*</span>
                    <input
                        type="checkbox"
                        checked={true}
                        disabled={true}
                    />
                    <span className="checkmark"></span>
                </label>
                <label className="checkbox-container">
                    <span>{locales.slack}</span>
                    <input
                        type="checkbox"
                        checked={slackNotificationEnabled}
                        onChange={(e) => {
                            setSlackNotificationEnabled(e.target.checked);
                            if (e.target.checked && (slackStatus === SlackStatus.None || SlackStatus.Revoked)) {
                                setShowSlackConfirm(true);
                            }
                        }}
                    />
                    <span className="checkmark"></span>
                </label>

                {renderPerferences()}

                <div className="buttons pull-right">
                    <button className={`btn primary continue-to-confirm ${loading ? 'loading' : ''}`} disabled={loading} onClick={() => {
                        gotoConfrim();
                    }}>{locales.continue_txt}{loading ? <Spinner isButton={true} /> : ''}</button>
                    <button className="btn secondary" onClick={() => {
                        setActiveWizard(PNSWIZARD.Region);
                        window.scrollTo(0, 0);
                    }}>{locales.back_txt}</button>
                    <button className="btn secondary" onClick={() => {
                        OMEGA.init().updateAndTrack("event", [{
                            ...eventDefaultValue,
                            element: "Cancelled subscription",
                            attributes: {
                                cancelledFrom: 'region'
                            }
                        }]);
                        histroyAPI.push("/proactive-notifications/manage");

                    }}>{locales.cancel_txt}</button>

                </div>
            </div>
        );
    }

    function renderRegionsTab() {
        return (
            <div className={`pns-wizard-item ${PNSWIZARD.Region} animate__animated animate__fadeIn active clearfix`}>
                {!showCustomRegions ? renderGlobalSection() : ''}
                <label className="custom-event" htmlFor="custom-event">
                    <input
                        className="group-selection"
                        onChange={(e) => { setGlobalEvent(!e.target.checked) }}
                        name="eventselection"
                        value="customevent"
                        id="custom-event"
                        type="radio"
                        checked={!globalEvent}
                    />
                    <span>{locales.region_customize}</span>
                </label>
                <div className={`region-custom-container ${!globalEvent ? 'active' : ''}`}>
                    <div className="select-info">{locales.region_info}</div>
                    {
                        selectedProducts.map((product: RegistryProduct) => {
                            return (
                                <div key={`product-custom-${product.id}`} className={`region-container custom service-${product.id}`}>
                                    <div className="product">{product.name}</div>
                                    <div className={`region ${window.locale}`}>
                                        <div className="title">{locales.region_locations}</div>
                                        {
                                            product.showEnvironment ? renderEnvironment(product) : renderRegion(product)
                                        }
                                    </div>
                                    <div className={`event-type ${window.locale}`}>
                                        <div className="title">{locales.event_types}</div>
                                        {renderEventTypes(product)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="buttons pull-right">
                    <button className={`btn primary continue-to-notification ${loading ? 'loading' : ''}`} disabled={loading || !allProductsRegionAndEventSelected()} onClick={() => {
                        gotoNextWizard();
                    }}>{locales.continue_txt}{loading ? <Spinner isButton={true} /> : ''}</button>
                    <button className="btn secondary" onClick={() => {
                        setActiveWizard(PNSWIZARD.Product);
                        window.scrollTo(0, 0);
                    }}>{locales.back_txt}</button>
                    <button className="btn secondary" onClick={() => {
                        OMEGA.init().updateAndTrack("event", [{
                            ...eventDefaultValue,
                            element: "Cancelled subscription",
                            attributes: {
                                cancelledFrom: 'region'
                            }
                        }]);
                        histroyAPI.push("/proactive-notifications/manage");

                    }}>{locales.cancel_txt}</button>

                </div>
            </div>
        )
    }

    function renderProductTab() {
       
        return (
            <div className={`pns-wizard-item ${PNSWIZARD.Product} animate__animated animate__fadeIn active clearfix`}>
                <div attt-data={entitlements.length} className={entitlements.length ? "entitlement-message show" : "entitlement-message"}>{locales.entitlement_message}</div>
                <div className="row">
                    {cloudList.map((cloud: RegistryCloud) => {
                        return (
                            <div key={`cloud-${cloud.id}`} className="col cloud-products">
                                <label {...(cloud.id === CLOUDIDS.ADOBE_CLOUD_PLATFORM_ID) ? { "data-tip": cloud.name } : {}} onClick={(e) => { cloud.isOpen = !cloud.isOpen; setCloudList([...cloudList]); }} className={cloud.isOpen ? 'title checkbox-container active' : 'title checkbox-container'} htmlFor={`cloud${cloud.id}`}>
                                    {cloud.name}<input type="checkbox" value={cloud.id} name={`cloud${cloud.id}`} />
                                    <span className="checkmark"></span>
                                    <ReactTooltip />
                                </label>

                                <div className="product-list">
                                    {
                                        cloud.cloudProducts.map((product: RegistryProduct) => {
                                            if (product.productOfferings?.length) {
                                                return (
                                                    <div key={`product-${product.id}`} className={product.isOpen ? 'product-container has-offering active' : 'product-container has-offering'}>
                                                        <i className="expand" onClick={(e) => { e.stopPropagation(); product.isOpen = !product.isOpen; setCloudList([...cloudList]); }}></i>
                                                        <label onClick={(e) => { e.stopPropagation(); product.active = !product.active; setCloudList([...cloudList]); }} className={`checkbox-container product-${product.id} ${product.partial ? 'partial' : ''} product-label service-offer-label`}>
                                                            <span className="text">{product.name}</span>
                                                            <input
                                                                onChange={(e) => {
                                                                    product.active = !product.active;
                                                                    product.partial = false;
                                                                    product.isOpen = true;
                                                                    if (product.productOfferings) {
                                                                        product.productOfferings = product.productOfferings.map((offer: RegistryService) => {
                                                                            offer.active = product.active;
                                                                            return offer;
                                                                        });
                                                                    }
                                                                    setCloudList([...cloudList]);
                                                                }}
                                                                data-offer-length="{{serviceOfferings.length}}" data-has-offer="true" type="checkbox"
                                                                name={`cloud${cloud.id}[]`}
                                                                value={product.id}
                                                                checked={!!product.active}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <div className="service-offerings ">
                                                            <ul>
                                                                {
                                                                    product.productOfferings.map((offering: RegistryService) => {
                                                                        return (
                                                                            <li key={`offering-${offering.id}`} >
                                                                                <label className={`checkbox-container offer-label offer-${offering.id}`}>
                                                                                    {offering.name}
                                                                                    <input
                                                                                        onChange={(e) => {
                                                                                            e.stopPropagation();
                                                                                            offering.active = !offering.active;
                                                                                            let selectedOffers = product.productOfferings?.filter((offer: RegistryService) => offer.active);

                                                                                            if ((selectedOffers && selectedOffers.length > 0) &&
                                                                                                (selectedOffers?.length !== product.productOfferings?.length)) {
                                                                                                product.partial = true;
                                                                                            } else {
                                                                                                product.partial = false;
                                                                                            }
                                                                                            product.active = !!selectedOffers?.length;

                                                                                            setCloudList([...cloudList]);
                                                                                        }}
                                                                                        className={`offer-${offering.id}`} type="checkbox"
                                                                                        name={`product${product.id}`} value={offering.id}
                                                                                        checked={!!offering.active}
                                                                                    />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div key={`product-${product.id}`} className="product-container no-offering">
                                                        <label className={`checkbox-container product-label product-${product.id}`}>
                                                            {product.name}
                                                            <input
                                                                onChange={() => {
                                                                    product.active = !product.active;
                                                                    setCloudList([...cloudList]);
                                                                }} type="checkbox"
                                                                name={`cloud${cloud.id}[]`}
                                                                value={product.id}
                                                                checked={!!product.active}

                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className={`select-product-message ${isCloudOpened() ? 'hide' : ''}`}>
                    <h2>{locales.click_to_select_products}</h2>
                </div>
                <div className="buttons pull-right">
                    <button
                        className={`btn primary continue-to-region ${loading ? 'loading' : ''}`}
                        disabled={disableButton() || loading}
                        onClick={() => {
                            gotoNextWizard();
                        }}
                    >{locales.continue_txt} {loading ? <Spinner isButton={true} /> : ''}</button>
                    <button className="btn secondary" onClick={() => {
                        OMEGA.init().updateAndTrack("event", [{
                            ...eventDefaultValue,
                            element: "Cancelled subscription",
                            attributes: {
                                cancelledFrom: 'product'
                            }
                        }]);
                        histroyAPI.push("/proactive-notifications/manage")
                    }} >{locales.cancel_txt}</button>

                </div>
            </div>
        )
    }

    function doneButtonText() {
        if (slackNotificationEnabled && slackSubscriptions.channel) {
            return locales.confirm_preferences;
        }
        if (slackStatus !== SlackStatus.Installed && slackNotificationEnabled) {
            return locales.confirm_and_setup_btn;
        }

        if ((slackStatus === SlackStatus.Installed || slackStatus === SlackStatus.RevokePending) && !slackNotificationEnabled) {
            return locales.confirm_and_remove_slack_btn;
        }

        return locales.confirm_preferences;
    }

    function renderConfirmTab() {
        return (
            <div className="pns-wizard-item confirm active animate__animated clearfix">
                {
                    unsubscribeAll ?
                        <>
                            <h3>{locales.no_subscriptions}</h3>
                            <div>{locales.no_product_info}</div>
                            <div>{locales.no_product_action_info}</div>
                        </> : <h3>{locales.confirm_subscription_title}</h3>
                }
                {renderPerferences()}
                {unsubscribeAll ? '' : renderConfirmTiles()}
                {showAPIError()}
                <div className="buttons pull-right">
                    <button className={`btn primary continue-to-confirm ${loading ? 'loading' : ''}`} disabled={loading || apiError} onClick={() => {
                        subscribe();
                    }}>{doneButtonText()}{loading ? <Spinner isButton={true} /> : ''}</button>
                    {(slackNotificationEnabled && slackSubscriptions.channel) ? <button className={`btn primary continue-to-confirm`} disabled={loading || apiError} onClick={() => {
                        subscribe();
                        installSlack();
                    }}>{locales.confirm_preferences_and_change_slack_channel_button}</button> : ""}
                    <button className="btn secondary" onClick={() => {
                        setActiveWizard(unsubscribeAll ? PNSWIZARD.Product : PNSWIZARD.Notification);
                        window.scrollTo(0, 0);
                    }}>{locales.back_txt}</button>
                    <button className="btn secondary" onClick={() => {
                        OMEGA.init().updateAndTrack("event", [{
                            ...eventDefaultValue,
                            element: "Cancelled subscription",
                            attributes: {
                                cancelledFrom: 'confirm'
                            }
                        }]);
                        histroyAPI.push("/proactive-notifications/manage")
                    }}>{locales.cancel_txt}</button>
                </div>

            </div>
        );
    }

    function renderPNSWizard() {
        if (pageLoading) {
            return <>{HideFooter(true)}<Spinner /></>
        } else {
            HideFooter(false)
            if (activeWizard === PNSWIZARD.Product) {
                return renderProductTab();
            } else if (activeWizard === PNSWIZARD.Region) {
                return renderRegionsTab();
            } else if (activeWizard === PNSWIZARD.Notification) {
                return renderNotificationTab();
            } else {
                return renderConfirmTab();
            }
        }

    }

    return (
        <div className="proactive-notifications-con">
            <div className="status-stripe pns-stripe">
                <div className="container">{locales.subscription_title}</div>
            </div>
            {showSlackConfirm ? renderSlackConfirm() : <></>}
            <div className="pns-wizard">
                <div className="container">
                    <div className="steps-progress">
                        <ul className="progressbar">
                            <li className={`step product ${isWizardCompleted(PNSWIZARD.Product) || activeWizard === PNSWIZARD.Product ? 'active' : ''}`}>{locales.select_products}</li>
                            <li className={`step region ${isWizardCompleted(PNSWIZARD.Region) || activeWizard === PNSWIZARD.Region ? 'active' : ''}`}>{locales.regions_events}</li>
                            <li className={`step notification ${isWizardCompleted(PNSWIZARD.Notification) || activeWizard === PNSWIZARD.Notification ? 'active' : ''}`}>{locales.notifications}</li>
                            <li className={`step confirm ${isWizardCompleted(PNSWIZARD.Confirm) || activeWizard === PNSWIZARD.Confirm ? 'active' : ''}`}>{locales.confirm}</li>
                        </ul>
                    </div>
                    {error ? <GenericError /> : renderPNSWizard()}
                </div>
            </div>
        </div>
    );

}