import React, { useState } from "react";

import "./helpoptions.scss";
import { useHistory } from "react-router-dom";

const HelpOptions = (props:any) => {
 const histroyAPI = useHistory();
  const eventOptions = [
    {
      text: "Guided Tour",
      id: 1,
    },
    {
      text: "How to subscribe to proactive notifications",
      id: 2,
    },
     {
      text: "Status APIs",
      id: 3,
    },
    {
      text: "Adobe Customer Support",
      id: 4,
    }
   
  ];
function restart(){
return <div>
    <button className="show-more-link" onClick={props.actionProvider.handleOptions} >
          Didn’t find what you were looking for? Restart
    </button>
</div>
}



 const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
const [ clicked, setIsClicked] = useState(false);
const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = eventOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsClicked(true);setIsDisabled(!isDisabled);setActiveIndex(index);setClassName("option-inactive");

switch(option.id){
case 1:
    histroyAPI.push("/nav");
    const elements:any = document.getElementsByClassName('menutext');
    if (elements.length > 0) {
        elements[0].click(); 
    }
        break;
case 2:histroyAPI.push('/help/proactivesubscriptions'); break;
case 3:window.open('https://developer.adobe.com/adobe-status/'); break;
case 4:window.open('https://experienceleague.adobe.com/home?support-tab=home#support');break;
}



}} className={activeIndex === index?"change-buttons":intitialClassName}  disabled={isDisabled}>
      {option.text}
    </button>
  ));


  return <div className="options-container">{buttonsMarkup}
{clicked?restart():<></>}
</div>;
};

export default HelpOptions;
