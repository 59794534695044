import React, { useState } from "react";

import "./Options.scss";

const Options = (props:any) => {
  const options = [
  
    {
      text: "Need help finding events ",
      handler: props.actionProvider.handleFindEvents,
      id: 1,
    },
    {
      text: "Issue with notifications ",
      handler: props.actionProvider.handleNotifications,
      id: 2,
    },
    {
      text: "Issue with subscriptions ",
      handler: props.actionProvider.displaySubscriptions,
      id: 3,
    },

     {
      text: "Something else ",
      handler: props.actionProvider.handleMoreHelp,
      id: 4,
    }
    
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = options.map((option:any,index:any) => (
    <><button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);option.handler();setClassName("option-inactive")}} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>
    <br/></>
  ));


  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
