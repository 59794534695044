import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import parse from 'html-react-parser';

import Options from "../../Options/Options";



import ShowEvents from "../../showevents/showevents";


import MyAvatar from '../../avatar/avatar';
import EventOptions from "../../suboptions/eventoptions";
import RegionDropdown from "../../regiondropdown/regiondropdown";
import FilterConfirmation from "../../filterconfirmation/filterconfirmation";
import HelpOptions from "../../suboptions/helpoptions";
import NotiOptions from "../../suboptions/notioptions";
import InBotSubscriptions from "../../inbotsubscriptions/inbotsubscriptions";
import CloseOptions from "../../suboptions/closeoptions";
import Home from "../home";


const imsProfile = sessionStorage.getItem('adobeid_ims_profile');
const profile = JSON.parse(imsProfile);

if(imsProfile){
var name = profile.displayName;
}else{
var name = "";
}

const config = {

  botName: "Adobe Status Virtual Assistant",
  initialMessages: [
    createChatBotMessage(parse(`<p>Hello ${name}.</p> <p> I am the Adobe Status Virtual assistant. I will try to help answer your questions or resolve any issues you are experiencing.</p>`)), 
createChatBotMessage(
      "Please select an option from below.",
      {
        withAvatar: true,
        delay: 500,
        widget: "options",
      }),
  ],
 

 customComponents: {
    botAvatar: (props) => <MyAvatar {...props} />,
    home: (props) => <Home {...props} />,
  },

  customMessages: {
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props} />,
      props: '',
      mapStateToProps:[]
    },
    
    {
      widgetName: "regiondropdown",
      widgetFunc: (props) => <RegionDropdown {...props} />,
      props: '',
      mapStateToProps:['regionprop']
    },
    {
      widgetName: "showevents",
      widgetFunc: (props) => <ShowEvents {...props} />,
      props: '',
      mapStateToProps:['findOngoingCSOs','findOngoingCMRs','findhistoricCSOs','findhistoricCMRs']
    },
   
    {
      widgetName: "eventoptions",
      widgetFunc: (props) => <EventOptions {...props} />,
      props: '',
      mapStateToProps:['findOngoingCSOs','findOngoingCMRs','findhistoricCSOs','findhistoricCMRs']
    },
    {
      widgetName: "helpoptions",
      widgetFunc: (props) => <HelpOptions {...props} />,
      props: '',
      mapStateToProps:['help']
    },
    {
      widgetName: "notioptions",
      widgetFunc: (props) => <NotiOptions {...props} />,
      props: '',
      mapStateToProps:['']
    },

     {
      widgetName: "inbotsub",
      widgetFunc: (props) => <InBotSubscriptions {...props} />,
      props: '',
      mapStateToProps:['']
    },

    {
      widgetName: "filterconfirmation",
      widgetFunc: (props) => <FilterConfirmation {...props} />,
      props: '',
      mapStateToProps:['filterConfirmation']
    },
    {
      widgetName: "closeoptions",
      widgetFunc: (props) => <CloseOptions {...props} />,
      props: '',
      mapStateToProps:['close']
    }
    
    
  ],
state: {
    myCustomProperty: '',
    myCustomEventProperty:'',
    product:"",
    subscriptions: "",
    intentmap: false,
    messagecustom:"",
    supportredirect:false,
    productSupport: false,
    sentiment:"",
    findEvent: false,
    dateRange:"",
    regionprop:"",
    findOngoingCSOs: false,
    findOngoingCMRs: false,
    findhistoricCSOs: false,
    findhistoricCMRs: false,
    filterConfirmation: false,
    help:"",
    notiisuse: false,
    subissue: false,
  },
};

export default config;



