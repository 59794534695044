import React, { useContext, useEffect, useState, useRef } from "react";

import "./inbotsubscriptions.scss";
import { SubscriptionService } from "../../models/subscription-service";
import { useHistory } from "react-router-dom";
import { ServiceRegistry } from "../../models/service-registry";
import { BsoMapping } from "../../models/mapping-service";
import { EventService } from "../../models/event-service";
import { ConfigContext } from "../../App";

const InBotSubscriptions = (props:any) => {
 

    const histroyAPI = useHistory()
    const [loggedIn, setLoggedIn] = useState(false);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [mounted, setMounted] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [selfSubscription, setSelfSubscription] = useState<any>();
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [visibleRows, setVisibleRows] = useState(3);
    const [mappingObj, setMappingObj] = useState<any>();
    const [activeIndex, setActiveIndex] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [intitialClassName, setClassName] = useState("option-button");
    const [isClicked, setIsClicked] = useState(false);
    const [scroll, setScroll] = useState(true);
    const locales = useContext(ConfigContext).appConfig.languageData;
    const historyAPI = useHistory();


     const suboptions = [
  
    {
      text: "Looks good",
      id: 1,
    },
    {
      text: "I want to edit my subscriptions",
      id: 2,
    },

     {
      text: "I need more help",
      id: 3,
    }
    
  ];

   const confirmOptions = [
  
    {
      text: "Yes",
      id: 1,
    },
    {
      text: "No",
      id: 2,
    }

  ];

  const thankyouOptions = [
  
    {
      text: "Close",
      id: 1,
    },
    {
      text: "Restart",
      id: 2,
    }

  ];

function scrollToElement () {
console.log("calling");
setTimeout(() => {
  const buttons = document.getElementsByClassName("createsub");
    buttons[0].scrollIntoView({ behavior: 'smooth' });
  console.log("buttons",buttons); // Should now log the buttons
}, 200);
};

const buttonsMarkup = suboptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);setClassName("option-inactive");

        switch(option.id){
            case 1: props.actionProvider.SubscriptionComplete(); break;
            case 2: setIsClicked(true); historyAPI.push('/proactive-notifications/subscriptions/edit'); break;
            case 3: setIsClicked(true);window.open('https://experienceleague.adobe.com/home?support-tab=home#support'); break;
        }

        }} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>));

const confirmMarkup = confirmOptions.map((option:any,index:any) => (
   <button key={option.id} onClick={()=>{setScroll(false);setIsDisabled(!isDisabled);setActiveIndex(index);setClassName("option-inactive");

        switch(option.id){
            case 1: setIsClicked(true);selfSubscription.length?historyAPI.push('/proactive-notifications/subscriptions/edit'):historyAPI.push('/proactive-notifications/subscriptions/create');break;
            case 2: props.actionProvider.SubscriptionComplete(); break;
          
        }

        }} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>));

const thankyouMarkup = thankyouOptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);setClassName("option-inactive");

        switch(option.id){
            case 1: props.actionProvider.SubscriptionComplete(); break;
            case 2: props.actionProvider.handleOptions();break;
        }

        }} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>));

    const handleShowMore = () => {
        setVisibleRows((prev) => prev + 3);
    };
    const subscriptionService: SubscriptionService = SubscriptionService.init();
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
    const eventService: EventService = EventService.init();
    const bsomapping: BsoMapping = BsoMapping.init();

    subscriptionService.setLocales(locales);
useEffect(() => {


        const [
            productPromise,
            servicePromise,
            bsoPromise
        ]: any = [
                eventService.fetch(),
                serviceRegistry.fetch(),
                bsomapping.fetch()
            ];

        Promise.all([productPromise, servicePromise,bsoPromise])
            .then((result: any) => [result[0].data, result[1].data, result[2].data])
            .then((result: any) => {
                if (mounted) {
                let mapping = result[2];
                setMappingObj(mapping);
                 if (window.adobeIMS?.isSignedInUser()) {
                    window.adobeIMS?.getProfile().then((profile: any) => {
                    if(profile?.email?.includes("@adobe.com") && profile?.emailVerified==="true"){
                        setLoggedIn(true);
                        }

                    })
            
                    setLoadingSubscription(true);
                    let state: any = histroyAPI?.location?.state;
                    let safe: boolean = !!state?.page;                 
                    const [eventSubscriptionPromise, selfSubscriptionPromise]: any = [
                    subscriptionService.getSubscriptions("event", safe),
                    subscriptionService.getSubscriptions("product", safe),
                    ];
                    Promise.all([eventSubscriptionPromise, selfSubscriptionPromise, window.adobeIMS?.getProfile()])
                    .then((result: any) => [result[0].data, result[1].data, result[2]])
                    .then((result: any) => {
                        if (mounted) {

                            let [eventSubscription, selfSubscriptions, IMSProfile]: any = result;
                            setSubscriptions(eventSubscription);
                            let selfSubs = serviceRegistry.mergeOfferingsIntoProduct(selfSubscriptions ,mapping ) || [];
                            setSelfSubscription(selfSubs.sort((a: any, b: any) => a.product.name.localeCompare(b.product.name)));
                            setLoadingSubscription(false);
                    
                        }

                    })
                    .catch(() => {
                        setLoadingSubscription(false);
                        setSubscriptionError(true);
                    });


            }



            }})
            .catch(() => {
               
            });

  

 return () => {
      setMounted(false);
    
    }

}, []);


function printOfferings(offerings: any, type?: any) {
       
        if (offerings && offerings.length) {
            offerings = offerings.sort((a: any, b: any) => a.name.localeCompare(b.name));
            return (
                <>
                    <span className="product-offerings">
                        <b>{locales.product_offering}: </b>
                    </span>
                    {offerings.map((offering: any, index: any) => {
                        return (
                            <span key={offering.id}>
                                {offering.name}{offerings.length === index + 1 ? "" : ", "}
                            </span>
                        )
                    })
                    }
            
                </>
            );

        }

        return <></>

    }




function printRegions(regions: any, mappingObj?: any) {

        if (regions && regions.length) {
            return (
                <>
                    <div className="rigion-selected">
                        <b>{locales.region_locations}: </b>
                        
                        {mappingObj ? regions.map((region:any,index: number)=>{let grpName = mappingObj.find((mapObj:any) => mapObj.groupId === region.id).groupName

                                    return (
                                            <span key={region.id}>{grpName}{regions.length === index + 1 ? "" : ", "}</span>
                                        )
                                    })
                                    :regions.sort((a: any, b: any) => a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'base' })).map((region: any, index: number) => {
                                        return (
                                            <span key={region.id}>{region.name}{regions.length === index + 1 ? "" : ", "}</span>
                                        )
                                    })
                        }
                    </div>
                </>
            );
        }
        return <></>
    }


   function joinEvents(events: any) {
        let result: any = "";
        for (var i = 0; i < events.length; i++) {
            result += events[i] === "1" ? locales.event_type_major : events[i] === "2" ? locales.event_type_minor : events[i] === "5" ? locales.event_type_messages : locales.event_type_maintence;
            if (i !== events.length - 1) {
                result += ", ";
            }
        }

        return result;
    }
function restart(){
return <div>
    <button className="show-more-link" onClick={props.actionProvider.handleOptions} >
          Didn’t find what you were looking for? Restart
    </button>
</div>
}

function displaySubscriptions(){


return <table  style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead className="sub-header">
          <tr className = "sub-row">
            <th  className = "sub-row">Cloud</th>
            <th  className = "sub-row">Product</th>
            <th  className = "sub-row">Other
            </th>
          </tr>
        </thead>
        <tbody className="sub-row">
          {selfSubscription.slice(0, visibleRows).map((row:any,index:any) => (
            <tr key={row.index}>
              <td  className = "sub-row">{row.cloudName}</td>
              <td  className = "sub-row">{row.product.name}</td>
               <td  className = "sub-row">
                {printOfferings(row.productOfferings)}
                {printRegions(row.regions)}
                {printRegions(row.environments,mappingObj)}
                <b>Events:</b> {joinEvents(row.eventType)}
             </td>
            </tr>
          ))}
        </tbody>
      </table>
   
}

return (
    <>
      <div>
        {loadingSubscription ? (
          <>Loading...</>
        ) : selfSubscription?.length ? (
          <>
            {displaySubscriptions()}
            {visibleRows < selfSubscription.length && (
              <button onClick={handleShowMore} className="show-more-link">
                Show More
              </button>
            )}
            <br />
            {props.state.notiisuse ? (
              <div className="options-container">{buttonsMarkup}</div>
            ) : (
              <>
                <div>Do you want to edit subscriptions?</div>
                <div className="confirmoptions-container">{confirmMarkup}</div>
              </>
            )}
          </>
        ) : (
          <>
           
            <div className="createsub"><span>Your haven't subscribed to any products</span><br/>
            <span> Do you want me to help setup subscriptions?</span>
              {scroll? (() => {
                    setTimeout(() => {
                        const elements = document.getElementsByClassName("createsub");
                        if (elements.length > 0) {
                            elements[elements.length - 1].scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 200);
                    return null; // Ensure nothing is returned for rendering
                })():<></>}
            
             </div>
            <div className="oconfirmoptions-container">{confirmMarkup}</div>
           
             
         
           
          </>
        )}
      </div>
        
      {isClicked ? restart() : <></>}
    
    </>
  );
  
};

export default InBotSubscriptions;
