import parse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import OMEGA from '../../../omega';
class ActionProvider {
  
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  eventDefaultValue = {
    element: "Personal Assistant",
    type: "Button",
    action: "click",
    widget: {
      name: "Personal Assistant",
      type: "button"
    },
    feature: "Personal Assistant Usage"
  }

  omega_capture(element, widget_name, widget_type){
    OMEGA.init().updateAndTrack("event", [{
      ...this.eventDefaultValue,
      element: element,
      widget: {
        name: widget_name,
        type: widget_type
      },
    }]);
  }

  closeChatbot = () => {
    const elements = document.getElementsByClassName('chatbot-icon');
    if (elements.length > 0) {
        elements[0].click(); 
    }
    this.omega_capture("Close Chatbot", "Personal Assistant", "Button");
    };
  
 
resetState = ()=>{

this.setState((state) => ({
      ...state,
      intentmap: false,
    }));
this.setState((state) => ({
      ...state,
      messagecustom: "",
    }));
this.setState((state) => ({
      ...state,
      productSupport: false,
    }));
this.setState((state) => ({
      ...state,
      supportredirect: false,
    }));
}

handleFindEvents = ()=>{

  this.omega_capture("Find Events", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "eventoptions",
    });
    this.addMessageToState(message);
}

handleOptions = ()=>{
  this.setState((state) => ({
      ...state,
      findOngoingCSOs: false,
      findOngoingCMRs: false,
      findhistoricCSOs: false,
      findhistoricCMRs: false,
    }));
  this.omega_capture("Launch ChatBot", "Personal Assistant", "Button");

const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "options",
    });
    this.addMessageToState(message);
}

handleCloseBot = () => {
  this.omega_capture("Close Chatbot", "Personal Assistant", "Button");
  const message = this.createChatBotMessage(parse(`Goodbye! Closing the chatbot.`));
  this.addMessageToState(message);
  this.closeChatbot();
}

handleMoreHelp = ()=>{
  this.omega_capture("More Help", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please select the category that you are looking for`),{
      widget: "helpoptions",
    });
    this.addMessageToState(message);
}
handleNotifications = ()=>{
  this.omega_capture("Notifications", "Personal Assistant", "Button");
 this.setState((state) => ({
        ...state,
        notiisuse: true,
      }));
const message = this.createChatBotMessage(parse(`Please select an option from below`),{
      widget: "notioptions",
    });
    this.addMessageToState(message);
}

displaySubscriptions = ()=>{
  this.omega_capture("Display Subscriptions", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Please check your current subscriptions`),{
      widget: "inbotsub",
    });
    this.addMessageToState(message);
}

SubscriptionComplete = ()=>{
  this.omega_capture("Subscription Complete", "Personal Assistant", "Button");
const message = this.createChatBotMessage(parse(`Thank You.`),{
  widget: "closeoptions",
});
    this.addMessageToState(message);
}

findOngoingCSOs = ()=>{
  this.omega_capture("Find Ongoing CSOs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findOngoingCSOs: true,
      }));
  const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
        widget: "filterconfirmation",
      });
  this.addMessageToState(message);
}

findOngoingCMRs = ()=>{
  this.omega_capture("Find Ongoing CMRs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findOngoingCMRs: true,
      }));
   const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
        widget: "filterconfirmation",
      });
  this.addMessageToState(message);
}

findLast7DaysCSOs = ()=>{
  this.omega_capture("Find Last 7 Days CSOs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findhistoricCSOs: true,
      }));
  const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
        widget: "filterconfirmation",
      });
  this.addMessageToState(message);
}

findLast7DaysCMRs = ()=>{
  this.omega_capture("Find Last 7 Days CMRs", "Personal Assistant", "Button");
this.resetState();
  this.setState((state) => ({
        ...state,
        findhistoricCMRs: true,
      }));
  const message = this.createChatBotMessage(parse(`Do you want to filer the results by Region and Product ?`),{
        widget: "filterconfirmation",
      });
  this.addMessageToState(message);
}

showFilter = ()=>{
  this.omega_capture("Show Filter", "Personal Assistant", "Button");
  this.setState((state) => ({
        ...state,
      }));
  const message = this.createChatBotMessage(parse(`Please select a region and product`),{
        widget: "regiondropdown",
      });
  this.addMessageToState(message);
}

handleFilter = (option1,option2)=>{
  const region= option1?option1:"All";
  const product= option2?option2:"All";
  this.omega_capture("Filter - "+region+" - "+product, "Personal Assistant", "Button");
  this.setState((state) => ({
        ...state,
        regionprop: option1,
        myCustomProperty: option2
      }));
  const message = this.createChatBotMessage(parse(`Here's a list of Events`),{
        widget: "showevents",
      });
  this.addMessageToState(message);
}

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  };
}

export default ActionProvider;



