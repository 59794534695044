import React, { useState } from 'react';
import Select from 'react-select';
import { ServiceRegistry } from '../../models/service-registry';
import CreatableSelect from 'react-select/creatable';
import { EventService } from '../../models/event-service';

import "./regiondropdown.scss";


 

export default function RegionDropdown(props:any) {
   const [regions, setRegions] = useState([]);
   const[  products, setProducts] = useState([]);
  const suboptions = [
    {
      text: "Ok",
      id: 1,
    },
    {
      text: "Cancel",
      id: 2,
    },
  ];
    const [activeIndex, setActiveIndex] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
   const [intitialClassName, setClassName] = useState("option-button");
  const buttonsMarkup = suboptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index); props.actionProvider.handleFilter(regions, products);setClassName("option-inactive");}} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>
  ));

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedProductOption, setSelectedProductOption] = useState(null);
    const serviceRegistry: ServiceRegistry = ServiceRegistry.init();


 const handleChange = (option: any) => {
    props.actionProvider.resetState();
    setSelectedOption(option);
    setRegions(option);
  };

const handleProductChange = (option: any) => {
    props.actionProvider.resetState();
    setSelectedProductOption(option);
    setProducts(option);
  };
 function getRegionNames() {
        let regionList:any =[];
        let allValues: any =[];
        var finalregionList: any = [];
       
        regionList = serviceRegistry.getRegionList();
        if (regionList && regionList.length) {
             regionList.forEach((region: any) => {
                allValues.push(region.id);
                finalregionList.push ({
                    value: region.id, label: region.name
                });
            });
        }
        finalregionList.push({value: allValues, label: "All"});
        return finalregionList;
        
    }

 function getproductNames() {
        let productList:any =[];
        productList = serviceRegistry.getAllProducts();
        if (productList && productList.length) {
            return productList.map((product: any) => {
                return ({
                    value: product.id, label: product.name
                });
            });
        }
        return [];
    }

  return (
    <div>
    <div className="dropdown-text">Please select region(s)</div>
    <Select
           
            isMulti
            value={selectedOption}
        
            onChange={option => handleChange(option)}
        
            options={getRegionNames()}
            isDisabled={isDisabled} 
        />
            
       
    <br/>
    <div className="dropdown-text">Please select product(s)</div>
       
    <Select
        
         isMulti
        value={selectedProductOption}
        onChange={option => handleProductChange(option)}
        options={getproductNames()}
        isDisabled={isDisabled} 
      />
        <br/>

         <div className="options-container">{buttonsMarkup}</div>


    <br/>
    </div>
  );
}