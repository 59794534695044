import React, { useState } from "react";

import "./filterconfirmation.scss";

const FilterConfirmation = (props:any) => {
const [activeIndex, setActiveIndex] = useState(null);
const [isDisabled, setIsDisabled] = useState(false);
 const [intitialClassName, setClassName] = useState("option-button");
  const suboptions = [
    {
      text: "Yes",
      handler: props.actionProvider.showFilter,
      id: 1,
    },
    {
      text: "No",
      handler: props.actionProvider.handleFilter,
      id: 2,
    },
  ];





  const buttonsMarkup = suboptions.map((option:any,index:any) => (
    <button key={option.id} onClick={()=>{setIsDisabled(!isDisabled);setActiveIndex(index);option.handler();setClassName("option-inactive");}} className={activeIndex === index?"change-buttons":intitialClassName} disabled={isDisabled}>
      {option.text}
    </button>
  ));


  return <div className="options-container">{buttonsMarkup}</div>;
};

export default FilterConfirmation;
