import React, { ElementRef, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { EventService } from '../../models/event-service';
import { ServiceRegistry } from '../../models/service-registry';
import EventTile from '../eventtile/eventtile';
import CreatableSelect from 'react-select/creatable';
import ContentLoader from 'react-content-loader'
import { Incident, IncidentGroup, Product, EventType } from '../../proptype';
import ReactTooltip from "react-tooltip";
import { ConfigContext } from "../../App";
import { SubscriptionService } from '../../models/subscription-service';
import parse from 'html-react-parser';
import { IMS_EVENT, OMEGA_ENV, SUBMENU, SHOW_CHATBOT,DARK_LAUNCH,ALLOWLIST_USERS } from '../../constants';
import EmptyMyEventsMessage from '../emptymyeventsmessage/emptymyeventsmessage';
import Spinner from '../spinner/spinner';
import Banner from '../banner/banner';
import OMEGA from '../../omega';
import { clearPageState, filterOngoingCanceledMaitenaceEvents, HideFooter, Subscribed } from '../../utils';
import GenericError from '../genericerror/genericerror';
import Navigation from '../navigation/navigation';
import 'react-chatbot-kit/build/main.css'

export default function Home(props:any) {


  const eventDefaultValue = {
    element: "All Events",
    type: "Button",
    action: "click",
    widget: {
      name: "personalization",
      type: "panel"
    },
    feature: "personalization tab"
  }


  const MyLoader = (props: any) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={100}
      backgroundColor="#f3f3f3"
      foregroundColor="#bcb8b8"
      style={{ marginTop: "10px" }}
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
      <rect x="80" y="17" rx="4" ry="4" width="100%" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="100%" height="10" />
    </ContentLoader>
  );

  const histroyAPI = useHistory()
  const dropdownRef = useRef<any>(null);
  const [events, setEvents] = useState<IncidentGroup[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cloudEvents, setCloudEvents] = useState([]);
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [currentCloudEvents, setcurrentCloudEvents] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selfSubscription, setSelfSubscription] = useState<any>();
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [isMyEvents, setIsMyEvents] = useState(false);
  const [isMyEventsLoading, setIsMyEventsLoading] = useState(true);
  const [myEvents, setMyEvents] = useState<any[]>([]);
  const [myEventsByType, setEventsByType] = useState<IncidentGroup[]>([]);
  const [myCurrentCloudEvents, setMyCurrentCloudEvents] = useState<IncidentGroup[]>([]);
  const [entitledProducts, setEntitledProducts] = useState<any[]>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFilterRequest, setIsFilterRequest] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [hasNewUpdate, setHasNewUpdate] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [navState, setNavState] = useState(0);
  const [navEventExpand, setNavEventExpand] = useState(false);
  const [allProducts, setAllProducts] = useState({
    products: [],
    offerings: []
  });
  const locales = useContext(ConfigContext).appConfig.languageData;
  const updateMenu = useContext(ConfigContext).updateMenu;

  const {eventId}: any = useParams();


  //choose the screen size 
  const handleResize = () => {
    if (window.matchMedia("only screen and (max-width: 1000px)").matches) {
      setIsMobile(true)
      // setNavState(0);
    } else {
      setIsMobile(false)
    }
  };

  const handleClickMyEvents = () => {
    sessionStorage.setItem("OverviewTab", "myevents");
    OMEGA.init().updateAndTrack("event", [{
      ...eventDefaultValue,
      element: "My Events",
    }]);
    if (!myEvents?.length) {
      setLoadingSubscription(true)
    }
    setIsMyEvents(true);
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  const handleClickAllEvents = () => {
    sessionStorage.setItem("OverviewTab", "all");
    setLoadingSubscription(false)
    setIsMyEvents(false);
    OMEGA.init().updateAndTrack("event", [{
      ...eventDefaultValue,
      element: "All Events",
    }]);
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  const serviceRegistry: ServiceRegistry = ServiceRegistry.init();
  const eventService: EventService = EventService.init();
  eventService.setLocales(locales);
  const subscriptionService: SubscriptionService = SubscriptionService.init();
//   if(props.nav === "true"){
//   setNavState(1);
// }


  useEffect(() => {
    if (hasNewUpdate) {
      loadEventData();
      if (dropdownRef.current?.state?.value?.length) {
        onChange(dropdownRef.current.state.value)
      }
      setHasNewUpdate(false);
    }
  }, [hasNewUpdate])



    useEffect(() => {
       
        if (openEvent && !loading && typeof eventId === 'string') {

            const _element = document.querySelector(`.id-${eventId}`);
            if (_element) {
                _element.classList.add('active');
                setTimeout(() => {
                    _element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 200)
                setOpenEvent(false);
               
            }

        }
    }, [openEvent, loading]);

  useEffect(() => {

    


    updateMenu('Overview');

    window.addEventListener("resize", handleResize);
    localStorage.removeItem('filterObj');
    setIsMobile(!!window.matchMedia("only screen and (max-width: 1000px)").matches);

    if(window.location.hash.includes("myevents")){
      setIsMyEvents(true);

    }

    if (window.adobeIMS) {
      getAllData();
    } else {
      document.addEventListener(IMS_EVENT, getAllData);
    }

    eventService.registerForUpdate(() => {
      setHasNewUpdate(true);
    });

    return () => {
      setMounted(false);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener(IMS_EVENT, getAllData);
    }

  }, []);

  function getAllData() {



    SUBMENU.forEach((cloud: any) => {
      if (cloud.path !== '/') {
        let cloudName = cloud.path.replace("/cloud", "");
        if (window.location.pathname.includes(cloudName)) {
          histroyAPI.push(window.location.pathname.replace(cloudName, `/cloud${cloudName}`))
          return;
        }
      }
    });
      if(window.location.pathname.includes("nav")){
        setNavState(1);
      }
    if(window.location.pathname.includes("myevents")){
      handleClickMyEvents();

    }

    let hierarchy_web = "";
    if (window.location.hash) {



      hierarchy_web = `home:notification:${window.location.hash}`
    }
    else {
      hierarchy_web = `home`
    }
    OMEGA.init().updateAndTrack('page', {
      hierarchy: hierarchy_web,
      name: "Home - Adobe System Status",
      url: encodeURIComponent(window.location.href),
      type: "home",
      env: OMEGA_ENV
    });

    if (window.location.hash) {
      histroyAPI.push(window.location.pathname);
    }

    let tab = sessionStorage.getItem("OverviewTab");
    if (tab === "myevents") {
      setIsMyEvents(true);
    } else {
      setIsMyEvents(false);
    }

    const [servicePromise, productPromise]: any = [serviceRegistry.fetch(), eventService.fetch()];
    Promise.all([servicePromise, productPromise])
      .then(() => {
        if (mounted) {
          window.scrollTo(0, 0);
          if (window.adobeIMS?.isSignedInUser()) {
           
            setLoadingSubscription(true);
            let state: any = histroyAPI?.location?.state;
            let safe: boolean = !!state?.page;
            clearPageState(createBrowserHistory());
            const [eventSubscriptionPromise, selfSubscriptionPromise]: any = [
              subscriptionService.getSubscriptions("event", safe),
              subscriptionService.getSubscriptions("product", safe),
            ];
            Promise.all([eventSubscriptionPromise, selfSubscriptionPromise, window.adobeIMS?.getProfile()])
              .then((result: any) => [result[0].data, result[1].data, result[2]])
              .then((result: any) => {
                if (mounted) {

                  let [eventSubscription, selfSubscriptions, IMSProfile]: any = result,
                    entitlements: any[] = subscriptionService.getImsEntitlementProducts(serviceRegistry.getServiceCodeMap(), IMSProfile),
                    myEventsPromiseResponse: any[] = [],
                    extractedSubscription = serviceRegistry.mergeOfferingsIntoProduct(selfSubscriptions);

                  if (eventSubscription?.length ||
                    extractedSubscription?.length ||
                    entitlements?.length
                  ) {
                    myEventsPromiseResponse = eventService.getMyEvents(eventSubscription, extractedSubscription, entitlements);
                    if (myEventsPromiseResponse?.length) {
                      setEventsByType(filterOngoingCanceledMaitenaceEvents(eventService.getOnGoingEventsByType(undefined, undefined, undefined, myEventsPromiseResponse)));
                      setMyCurrentCloudEvents(eventService.getOnGoingEventsByCloud(undefined, undefined, undefined, myEventsPromiseResponse));
                      setEntitledProducts(entitlements);
                      setMyEvents(myEventsPromiseResponse);
                    }
                  }
                  if (eventSubscription?.length && myEventsPromiseResponse?.length) {
                    setSubscriptions(eventSubscription);
                  }
                  setSelfSubscription(selfSubscriptions);
                  setLoadingSubscription(false);
                  setIsMyEventsLoading(false);
                }

              })
              .catch(() => {
                setLoadingSubscription(false);
                setIsMyEventsLoading(false);
                setSubscriptionError(true);
              });


          }

          loadEventData();
          setLoading(false);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        setError(true);
      });
  }

  function loadEventData() {
    const onGoingEvents: IncidentGroup[] = filterOngoingCanceledMaitenaceEvents(eventService.getOnGoingEventsByType());
    const eventsByCloud: any = eventService.incidentsCloud();
    const ongoingCloudEvents: any = eventService.getOnGoingEventsByCloud();
    const productsAndOfferings: any = serviceRegistry.getProductsAndOfferings();
    setAllProducts(productsAndOfferings);
    setEvents(onGoingEvents);
    setCloudEvents(eventsByCloud);
    setcurrentCloudEvents(ongoingCloudEvents);
     let incident = eventService.getIncident(eventId);
      if (incident) {  
          setOpenEvent(true);
      }
  }

  function redirectCloud(cloudId: any) {
    let currentMenu = SUBMENU.filter((menu: any) => menu.id === cloudId);
    if (currentMenu.length) {
      updateMenu(currentMenu[0].name);
      histroyAPI.push(currentMenu[0].path);
    }
  }

  function getClouds() {

    return cloudEvents.map((incident: any) => {
      return (
        <li key={incident.cloud.cloudId}>
          <span className="cloud-name clearfix" data-tab="{incident.cloud.cloudId}" onClick={() => {
            redirectCloud(incident.cloud.cloudId);
          }}>
            <i className={`icon ${incident.icon}`}></i>
            <span className="text">{incident.cloud.cloudName}</span>
          </span>
          {
            incident.statusDetails.map((status: any, index: number) => {
              return (
                <span key={index} className="status"><i className={status.eventType.includes(EventType.maintenance) ? `event-icon ${status.eventType}` : `event-icon ${status.eventType.split("_")[0]}`}></i><span className="cloud-message">{status.eventString}</span></span>
              );
            })
          }
        </li>
      );
    })
  }

  function renderEventNavHelp(index: any, item: any, cloudindex?:any, foundFirstEvent?:any) {

  var indices = findMobileNavCloudIndex(currentCloudEvents); //[cloudIndex, eventIndex, event type]
    if ((!isMobile&&index === 0 ) || (isMobile && indices[0] === cloudindex && indices[1] === index)) {
      var eventTypes: any;
      if (navState === 4) {
       
        eventTypes = getEventTypes();

          if (!isMobile && eventTypes.length > 0 && eventTypes.includes((item._type).toLowerCase())) {
            if ((item._type) === eventTypes[0]) {
             
              return (<>
                <Navigation eventExpand={navEventExpand} eventState={item._type} isMobile={isMobile} eventType={item.eventType} navstate={setNavState} navstep={navState} rightalign={true} setExpand={setNavEventExpand}/>
              </>);
            }
          }else if(isMobile){
            return (<>
                <Navigation eventExpand={navEventExpand} eventState={item._type} isMobile={isMobile} eventType={item.eventType} navstate={setNavState} navstep={navState} rightalign={true} setExpand={setNavEventExpand}/>
              </>);
          }
      }
      if (navState === 5) {
        
        eventTypes = getEventTypes();
        if (!isMobile && eventTypes.length > 0 && eventTypes.includes((item._type).toLowerCase())) {
          if (item._type === eventTypes[0]) {
         
            return (<>
              <Navigation eventExpand={navEventExpand} isMobile={isMobile} eventState={item._type}eventType={item.eventType} navstate={setNavState} navstep={navState} rightalign={true} setExpand={setNavEventExpand}/>
            </>);
          }

        }
        else if(isMobile){
              return (<>
                  <Navigation eventExpand={navEventExpand} isMobile={isMobile} eventState={item._type} eventType={item.eventType} navstate={setNavState} navstep={navState} rightalign={true} setExpand={setNavEventExpand}/>
                </>);
        }
      }
    }
  }

  function getEventTypes() {

    const issueTypes = [EventType.major, EventType.minor, EventType.potential];
    const maintenanceTypes = [EventType.maintenance];
    var issueExist = false, maintenanceExist = false;
    var eventTypes: any[] = [];
    events.forEach((event: any) => {
      if (event.eventType !== "global" && event.eventType !== "cloud" && event.eventType !== "product") {
       
          if (!issueTypes.some(item => eventTypes.includes(item)) && issueExist === false) { // only get first event type
            eventTypes.push(event.list[0]._type);
            issueExist = true;
          } else if (maintenanceTypes.includes(event.eventType) && !maintenanceTypes.some(item => eventTypes.includes(item)) && maintenanceExist === false) { // only get first maintenance type
            eventTypes.push(event.list[0]._type);
            maintenanceExist = true;
          }
      }
    })
    return eventTypes;

  }
  function getEventTiles() {

    return events.map((incident: any, index1: any) => {
      return (
        <div key={index1}>

          {incident.list.map((item: any, index: any) => {
            return (


              <div key={index}>

                {
                  <EventTile event={item} key={index} isHistory={false} isSubscribed={Subscribed(item,subscriptions)} subscriptionLoading={loadingSubscription} error={subscriptionError} expandall={isExpanded} myevents={isMyEvents}
                  />
                }

                {renderEventNavHelp(index, item)}
              </div>
            );
          })}
        </div>
      );
    });
  }

  function isUserEntitledAndSubscribed() {
    return (
      (subscriptions?.length) ||
      (selfSubscription?.subscriptions?.length) ||
      entitledProducts?.length
    );
  }

  function isMyEventsRecommended() {
    return myEventsByType.some((incident: IncidentGroup) => incident.list.length);
  }

  function isMyCurrentCloudEventsRecommended() {
    return myCurrentCloudEvents.some((incident: IncidentGroup) => incident.list.length);
  }

  function isShowExpandAndCollapse() {
    if (isMyEvents) {
      return isMyEventsRecommended();
    } else {
      return events.some((incident: IncidentGroup) => incident.list.length);
    }
  }

  function getMyEventTiles() {
    if (
      (isUserEntitledAndSubscribed() && isMyEventsRecommended()) ||
      isFilterRequest
    ) {
      if (myEventsByType?.length) {
        return myEventsByType.map((incident: any, indexType: number) => {
          return (
            <div key={indexType}>
              {incident.list.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    {
                      <EventTile
                        event={item}
                        key={index}
                        isHistory={false}
                        isSubscribed={Subscribed(item,subscriptions)}
                        subscriptionLoading={loadingSubscription}
                        error={subscriptionError}
                        expandall={isExpanded}
                      />
                   
                    }
                     {renderEventNavHelp(index, item)}
                  </div>
                );
              })}
            </div>
          );
        });
      } else {
        return appliedFilter ? <p className="no-events"><i className="event-icon available"></i> {locales.no_event_found}</p> : ""
      }
    } else if (isUserEntitledAndSubscribed() && !isMyEventsRecommended()) {
      return getReviewSubscriptionScreen();
    } else {
      return getaddSubscriptionScreen();
    }
  }

  function getaddSubscriptionScreen() {
    return (
      <EmptyMyEventsMessage
        title={locales.subscribe}
        message={locales.my_event_logged_in_empty_sub_entlmnt}
        type="1"
        buttonText={locales.subscribe}
      ></EmptyMyEventsMessage>
    );
  }
  function getReviewSubscriptionScreen() {
    return (
      <EmptyMyEventsMessage
        title={locales.my_events_empty_events_header}
        message={locales.my_events_empty_events}
        type="1"
        buttonText={locales.manage_subscriptions}
      ></EmptyMyEventsMessage>
    );
  }

  function getEmptyLoginScreen() {
    return (
      <EmptyMyEventsMessage
        title={locales.my_events_sign_in_button}
        message={locales.my_events_anonymous_user_sign_in}
        buttonText={locales.my_events_sign_in_button}
        type="2"
      />
    );
  }

  function getMobileEventString(incident: any) {

    let count: number = 0, map_string: string = "", mainEventCount: number, eventString: string;
    let mainEventType = incident.status[0].eventType;
    incident.statusDetails.forEach((eventType: any) => {
      if (eventType.type !== mainEventType) {
        count += 1;
      }
    })
    if (count && !mainEventType.includes("resolved")) {
      mainEventCount = incident.statusDetails.filter((statuslist: any) => statuslist.type === mainEventType).length;
      eventString = mainEventCount > 1 ? "_events" : "_event";
      map_string = locales[(mainEventType) + eventString];
      if (map_string) {
        map_string = map_string.replace(`{${mainEventType}Count}`, mainEventCount.toString())
          + locales.other_incidents_banner.replace("{otherIncidentsCount}", count);
      }
    } else if (count && mainEventType.includes("resolved")) {
      map_string = incident.status[0].eventString + locales.other_incidents_banner.replace("{otherIncidentsCount}", count);
    }
    else {
      map_string = incident.status[0].eventString;
    }
    return map_string;
  }

function findMobileNavCloudIndex(navEvents:any){

  var cloudindex;
  var eventindex;
  var found = false;
  var type;
  navEvents.forEach((eventObj:any, index:any)=>{
    if (found) return;
    eventObj.list.forEach((event:any, indexele:any)=>{
      if (found) return;
      if(event.eventType!=="Global" && event.eventType!=="Product" && event.eventType!=="Cloud"){
        cloudindex = index;
        eventindex =  indexele;
        found=true;
        type=event._type;
      }

    })

  })

  return [cloudindex,eventindex,type];
}

  function getEventsMobileTile(currentCloudEvents: any) {

    return currentCloudEvents.map((incident: any, index: any) => {
      return (
        <ul className="event-list" key={index}>
          <li key={index}>
            <span className="cloud-name clearfix" data-tab="{incident.cloud.cloudId}" onClick={() => {
              redirectCloud(incident.cloud.cloudId);
            }}>
              <i className={`icon ${incident.icon}`}></i>
              <span className="text">{incident.cloud.cloudName}</span>
              <span className="status-mobile">
                <i className={`event-icon ${incident.status[0].eventType.split("_")[0]}`}></i>
                <i className={`event-color ${incident.status[0].eventType.split("_")[0]}`}></i>
                {getMobileEventString(incident)}
              </span>
            </span>
          </li>
          
          {incident.list.length
            ? <div className="mobile-tiles">
              {incident.list.map((item: any, listindex: any) => {
                return (
                  <div key={listindex}>
                    {<EventTile event={item} key={listindex} isHistory={false} isSubscribed={Subscribed(item,subscriptions)} subscriptionLoading={loadingSubscription} error={subscriptionError} expandall={isExpanded} />}
                     
                    {renderEventNavHelp(listindex, item, index)}
                  </div>
                  
                );
              })
              }
            </div>
            : ""}
          <hr />
        </ul>
      )
    })
  }

  function getEventSeperator() {
    return (
      <div id="eventTabs">
        <ul className="event-tabs" >
          <li
            className={isMyEvents ? "" : "active"}
            onClick={handleClickAllEvents}
          >
            {locales.all}
          </li>
          <li
            className={isMyEvents ? "active" : ""}
            onClick={handleClickMyEvents}
          >
            {locales.my_events}
            <a href="#/"
              data-for="main"
              data-tip={locales.my_events_icon_info}
              data-iscapture="true"
            >
              <span className="icon my-events-icon">
                <ReactTooltip
                  id="main"
                  place="right"
                  type="dark"
                  effect="float"
                  multiline={true}
                />
              </span>
            </a>
          </li>
        </ul>

      </div>
    )
  }

  function getMyEventsMobileTile() {
    if (!isFilterRequest) {
      if (isUserEntitledAndSubscribed() && isMyCurrentCloudEventsRecommended()) {
        return getEventsMobileTile(myCurrentCloudEvents);
      } else if (isUserEntitledAndSubscribed() && !isMyCurrentCloudEventsRecommended()) {
        return getReviewSubscriptionScreen();
      } else {
        return getaddSubscriptionScreen();
      }
    } else {
      return getEventsMobileTile(myCurrentCloudEvents)
    }
  }


  function getMobileTiles() {
    return (
      <>
        <div className="col-md-12 clearfix">
          {getEventSeperator()}
         
        </div>
        {navState === 3 ? <Navigation setExpand={setNavEventExpand} navstate={setNavState} navstep={navState} type={isMyEvents?findMobileNavCloudIndex(myCurrentCloudEvents)[2]:findMobileNavCloudIndex(currentCloudEvents)[2]}eventIndex={isMyEvents?findMobileNavCloudIndex(myCurrentCloudEvents)[0]:findMobileNavCloudIndex(currentCloudEvents)[0]} mobilenav={true}/> : <></>}
        
        {
          isMyEvents ? window.adobeIMS.isSignedInUser() ? isMyEventsLoading ? <Spinner /> : subscriptionError ? <div className="error-info"><p>{parse(locales.api_error_overview)}</p></div> : getMyEventsMobileTile() : getEmptyLoginScreen() : getEventsMobileTile(currentCloudEvents)
        }
      </>
    )
  }

  function onChange(e: any) {

    setAppliedFilter(true);
    let selectedOptions: any = [];
    if (e.length) {
      setAppliedFilter(true);
    } else {
      setAppliedFilter(false);
    }
    e.forEach((event: any) => {
      selectedOptions.push(event.value);
      OMEGA.init().updateAndTrack("event", [{
        ...eventDefaultValue,
        element: "Search dropdown",
        action: "change",
        type: "List items",
        name: event.value,
        attributes: {
          name: event.value
        },
        widget: {
          name: event.type ? event.type + " search" : "Search term used",
          type: "form"
        },
        search: {
          term: event.value
        },
        feature: "Search in Overview page dropdown"
      }]);
    })
    window.sessionStorage.setItem('search', JSON.stringify(selectedOptions));
    let filteredEventsMobile: any = [];
    filteredEventsMobile = eventService.getOnGoingEventsByCloud(undefined, undefined, selectedOptions);
    setcurrentCloudEvents(filteredEventsMobile);
    let filteredEvents: any = [];
    filteredEvents = filterOngoingCanceledMaitenaceEvents(eventService.getOnGoingEventsByType(undefined, undefined, selectedOptions));
    setEvents(filteredEvents);
    if (selectedOptions.length > 0) {
      setIsFilterRequest(true);
    } else {
      setIsFilterRequest(false);
    }
    if (window.adobeIMS?.isSignedInUser()) {
      window.adobeIMS?.getProfile().then((profile: any) => {
        let extractedSubscription = serviceRegistry.mergeOfferingsIntoProduct(selfSubscription),
          entitlements: any[] = subscriptionService.getImsEntitlementProducts(serviceRegistry.getServiceCodeMap(), profile),
          _myEvents = eventService.getMyEvents(subscriptions, extractedSubscription, entitlements);
        if (_myEvents?.length) {
          setMyEvents(_myEvents);
        }
        let filteredMyEventsByType = filterOngoingCanceledMaitenaceEvents(eventService.getOnGoingEventsByType(undefined, undefined, selectedOptions, _myEvents)),
          filteredMyEventsByCloud = eventService.getOnGoingEventsByCloud(undefined, undefined, selectedOptions, _myEvents);
        setMyCurrentCloudEvents(filteredMyEventsByCloud);
        setEventsByType(filteredMyEventsByType);
      });
    }

  }
  const formatOptionLabel = (option: any) => {
    if (option.productName) {
      return (
        <div className='dd-name'>
          <div>{option.label}</div>
          <div className='product-guide' style={{ fontSize: "11px", color: "#999" }}>
            {option.productName}
          </div>
        </div>
      );
    }

    return (
      <div className='dd-name'>
        <div>{option.label}</div>
      </div>
    );


  }

  function selectDropdown() {

    let filterOption: any[] = [{
      label: locales.products,
      options: allProducts.products.map((product: Product) => {
        return {
          "value": product.id, "label": product.name, "type": locales.products
        }
      }),
    },

    {

      label: locales.product_offering,
      options: allProducts.offerings.map((offering: any) => {
        return {
          "value": offering.id, "label": offering.name, productName: serviceRegistry.getProductNameofOffer(offering.id), "type": locales.product_offering
        }
      }),
    }];
    return (
      <CreatableSelect
        ref={(ref: ElementRef<any>) => {
          if (ref) {
            dropdownRef.current = ref;
          }
        }}
        isMulti
        classNamePrefix="service-dd"
        options={filterOption}
        onChange={onChange}
        isDisabled={loadingSubscription}
        formatOptionLabel={formatOptionLabel}
        placeholder={locales.search_placeholder}
        noOptionsMessage={() => locales.search_no_results}
        formatCreateLabel={userInput => `${locales.search_text} ${userInput}`}
      />
    );
  }


  function filterNavEvents(navevents:any){
    return navevents.filter((navEventItem: any)=> {return navEventItem.type !== EventType.announcement && !navEventItem.type.includes("resolved") && !navEventItem.type.includes("completed")} )
  }

  function filterResolvedevents(navevents:any){
    return navevents.filter((navEventItem: any)=> {return navEventItem.type.includes("resolved") || navEventItem.type.includes("completed")} )
  }



  function renderEventList() {
    if (error) {
      return (
        <div className="container">
          <div className="error-wrapper">
            <GenericError />
          </div>
        </div>
      );
    } else {
      if (isMobile) {
        HideFooter(false);
        return (
          <div className="row">
            <div className="col-md-12">
              <ul className="mobile-list">
                {subscriptionError && events?.length ? <div className="error-info"><p>{parse(locales.api_error_overview)}</p></div> : ""}
                {loading ? <MyLoader ></MyLoader> : getMobileTiles()}
              </ul>
            </div>
          </div>
        );
      }
      return (
        <div>
          {loading ? <div className="container" >

            <MyLoader></MyLoader>
            {HideFooter(true)}
          </div>
            :<>  {HideFooter(false)}
                <div className="container overview-wrapper">
             
              <section className="overview-list">
                <div className="row">
                  <div className="col-md-12">

                    <ul className="cloud-list clearfix">
                      <div className="cloud-panel">{getClouds()}</div>
                    </ul>

                  </div>
                </div>
              </section>
              {subscriptionError && events?.length ? <div className="error-info"><p>{parse(locales.api_error_overview)}</p></div> : ""}
              <div className="row">
                <div className="col-md-12 clearfix overview-tab">
                  {isShowExpandAndCollapse() ? (
                    <div className="expand-collapse">
                      <ul>

                        <li className={!isExpanded ? 'active' : ''} onClick={() => { setIsExpanded(false) }}>{locales.incident_toggle_collapse}</li>
                        <li className={isExpanded ? 'active' : ''} onClick={() => { setIsExpanded(true) }}>{locales.incident_toggle_expand}</li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                  {(events && events.length) || appliedFilter ? getEventSeperator() : ""}
                  {/* <div className="navigation-help"> <ul><li onClick={() => { setNavState(1); setNavEventState(0) }}>Navigation Help</li></ul></div> */}

                </div>
              </div>
              {navState === 3 ? <Navigation setExpand={setNavEventExpand} navstate={setNavState} navstep={navState} resolvedEvents={isMyEvents?filterResolvedevents(myEventsByType):filterResolvedevents(events)}events={isMyEvents?filterNavEvents(myEventsByType):filterNavEvents(events)}/> : <></>}
              {((events && events.length) || appliedFilter)
                ? window.adobeIMS.isSignedInUser() ? isMyEvents ? isMyEventsLoading ? <Spinner /> : subscriptionError ? <div className="error-info"><p>{parse(locales.api_error_overview)}</p></div> : getMyEventTiles()
                  : events && events.length > 0 ? getEventTiles() : appliedFilter ? <p className="no-events"><i className="event-icon available"></i> {locales.no_event_found}</p> : ""
                  : isMyEvents ? getEmptyLoginScreen() : events && events.length > 0 ? getEventTiles()
                    : appliedFilter ? <p className="no-events"><i className="event-icon available"></i> {locales.no_event_found}</p> : ""
                : ""
              }
            </div></>


          }
        </div>
      );
    }
  }


  function initialNav() {
    return (<Navigation navstate={setNavState} navstep={navState} />);
  }



  return (

<div>
    <div className="overview-container">
    
      <Banner isParentLoading={loading} showOnlyScheduledCanceledMaintenace={true} navstate={setNavState} />
      {navState === 1 ? initialNav() : <></>}
      {navState === 2 ? <Navigation isMobile={isMobile} navstate={setNavState} navstep={navState} rightalign={true} events={events}/> : <></>}
      <div className={isMobile ? "" : "container"}>
        <div className="row">
          <div className={isMobile ? "col-md-12 mobile-list " : "col-lg-3 offset-lg-9 col-md-4 offset-md-8"}>
            <div className={isMobile ? "mobile-dropdown" : "dropdown"}>{selectDropdown()}</div>
          </div>
        </div>
      </div>
      {renderEventList()}
</div>
</div>
  )
}